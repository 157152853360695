//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.kt-widget.kt-widget--user-profile-1 .kt-widget__items.nav .kt-widget__item.active {
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    background: #f2f3f7;
    border-radius: 4px;
}

.pac-container{
    z-index: 100000!important;
}

.nav-tabs{
    border: 0;
    margin-bottom: 16px;
}

.my-custom-style {
    padding: 1rem 0.5rem;
    color: kt-brand-color();

    .my-custom-element {
        margin-left: 1rem;
    }
}

@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}
